//SPACING
$block: 6px;
$spacing-map: (
  none: 0,
  1: $block/2,
  2: $block,
  3: $block * 1.5,
  4: $block * 2,
  5: $block * 3,
  6: $block * 4,
  7: $block * 5

);

@function spacing($spacing: 6, $multiply-value: 1) {
  $fetched-value: map-get($spacing-map, $spacing);

  @if type-of($fetched-value) == number {
    @return $fetched-value * $multiply-value;
  } @else {
    @error 'Spacing variant `#{$spacing}` not found. Available variants: #{available-names($spacing-map)}';
  }
}
