@import 'src/styles/_foundations.scss';
.Message {
  font-size: heading-font-size(h1);
  line-height: heading-line-height(h1);
  display: inline-block;
  padding: spacing(4,3) spacing(4,4) spacing(4,3);
  background-color: color(white, light);
  box-shadow: inset 0 -20px 44px 0 color(blue, background), inset -20px -20px 5px 0 color(white, light);
  border-radius: spacing(4,8) spacing(4,2) spacing(4,8) spacing(4,8);
  margin: spacing(4);
  color: color(black);
  z-index: 10;
  font-family: font-family(display);
  font-weight: 300;
  transition: transition();
  &.Message--Bot {
    background-color: color(violet, background);
    border-radius: spacing(4,2) spacing(4,8) spacing(4,8) spacing(4,8);
    box-shadow: inset 0 -20px 44px 0 color(violet, mid), inset -12px -12px 25px 0 rgba(255,255,255,.2);
  }
}

.Recording .Message {
  color: color(black);
  background-color: color(white, light);
  box-shadow: inset 0 -20px 44px 0 color(blue, background), inset 0px 20px 48px 0 transparentize(color(red), .5);
  &.Message--Bot {
    background-color: color(violet, background);
    box-shadow: inset 0 -20px 44px 0 color(violet, mid), inset 0px 20px 48px 0 transparentize(color(red), .5);
  }
}
