@import 'styles/_foundations.scss';
.Nav {
  position: fixed;
  top: 0;
  right: 0;
  background-color: color(white);
  padding: spacing(2) spacing(4);
  z-index: 120;
  span {
    display: inline-block;
    margin: 0 spacing(2);
  }
  a {
    text-decoration: none;

  }
  .--active {
    opacity: .3;
  }
}
