@import 'src/styles/_foundations.scss';
.Header {
  padding-bottom: spacing(4);
  display: flex;
  position: relative;
  z-index: 125;
  .title {
    opacity: .5;
    min-width: 300px;
    transition: transition(fast);
    transition-delay: 0;
  }
  .logo {
    img{
      position: relative;
      width: spacing(4,2.5);
      top: -5px;
      transition: transition(fast);
    }
    margin-right: spacing(4);
  }
  .nav {
    width: 100%;
    text-align: right;
    position: relative;
    top: -12px;
  }
}

.Recording .Header {
  .logo {
    img{
      filter: grayscale(100%) brightness(100);

    }

  }
  .title {
    opacity: 1;
    color: color(white);
  }
}
