// A DEFINIR
$color-map: (
  white: (
    base: #F0F4F8,
    light: #ffffff,
    dark: #DAE1E8
  ),
  black: (
    base: #1F1F1F,
  ),

  blue: (
    base: #0082E5,
    mid: #BEE0F9,
    background: #DDEFFC,
    dark: #001B4E
  ),
  red: (
    base: #E84D60,
    mid: #F4A5B0,
    background: #FBDBDF
  ),
  pink: (
    base: #E499C9,
    mid: #EFC1DF,
    background: #F4D5E9
  ),
  violet: (
    base: #9200FF,
    mid: #D6C4ED,
    background: #E0D2F2
  ),
  green: (
    base: #25E3AA,
    mid: #94EBCE,
    background: #D5F9EE
  ),
  sand: (
    base: #F1B48F,
    mid: #F5CAB0,
    background: #FAE1D2
  ),
  yellow: (
    base: #FFC228,
    mid: #FFD470,
    background: #FFF2D7
  ),



);

@function color($hue, $value: base) {
  $fetched-color: map-get(map-get($color-map, $hue), $value);

  @if map-has-key($color-map, $fetched-color) {
    $fetched-color: map-get(map-get($color-map, $fetched-color), $value);
  }

  @if type-of($fetched-color) == color {
    @return $fetched-color;
  } @else {
    @error 'Color `#{$hue} - #{$value}` not found. Available colors: #{available-names($color-map)}';
  }
}

@function rgbaColor($color, $alpha: 0) {
  $full-color: $color;
  @return rgba($full-color, $alpha);
}
