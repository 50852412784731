@import 'styles/_foundations.scss';
.Dictaphone {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 100;
  pointer-events: none;
  .controls {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100vw;
    transition: transition();
    pointer-events: all;
    color: color(black);
    padding: 5vw;
    height: 160px;
    .controls__transcript {
      font-size: heading-font-size(h1);
      line-height: heading-line-height(h1);
      font-family: font-family(display);
      font-weight: 300;
      color: color(black);
      white-space: nowrap;

      width: 100%;
      position: absolute;
      top: spacing(2,1);
      left: calc(4vw + 180px);

      span {
        top: 0;
        white-space: nowrap;
        display: inline-block;
        position: absolute;
        right: 0;
        min-width: 100%;
      }
    }
    .controls__buttons {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0 5vw;
      .button {
        width: spacing(4,12);
        height: spacing(4,12);
        border-radius: spacing(4, 6);
        background-color: color(red);
        border: 0;
        font-size: heading-font-size(h1);
        line-height: spacing(4,12);
        font-family: font-family(display);
        font-weight: 300;
        position: relative;
        img {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

}

.Recording .Dictaphone {
  .controls {
    background-color: color(red);
    .controls__transcript {
      color: color(white);

    }
    .controls__buttons {

      .button {
        animation: pulse 0.8s infinite;
      }
    }
  }
}


@keyframes pulse {
	0% {
    background-color: mix(color(white, light), color(red), 0);
	}

	50% {
    background-color: mix(color(white, light), color(red), 30);
	}

	100% {
    background-color: mix(color(white, light), color(red), 0);
	}
}
